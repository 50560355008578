



























































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Widget from "@/components/common/Widget.vue";
import DataTable from "@/components/table/DataTable.vue";
import store from "../../store";
import api from "../../api";
import { Watch } from "vue-property-decorator";
import lodash from "lodash";
import SelectFilter from "@/components/common/SelectFilter.vue";
import {
  setAgencyStorage,
  getAgencyStorage,
  getClientStorage,
} from "@/helper/utils";

declare const $: any;

@Component({ components: { Breadcrumb, Widget, DataTable, SelectFilter } })
export default class AgencyClients extends Vue {
  tableColums = [
    {
      label: "ID",
      prop: "id",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Kanzlei",
      prop: "agency",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Name",
      prop: "name",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Mandant",
      prop: "mnumber",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Importieren",
      prop: "doexport",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Aktiv",
      prop: "active",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Beginn Monat",
      prop: "startmonth",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Beginn Jahr",
      prop: "startyear",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Ende Monat",
      prop: "endmonth",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Ende Jahr",
      prop: "endyear",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "SKR",
      prop: "skr",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Datev Beraternummer",
      prop: "consultantnumber",
      bodyCellClass: "text-left",
      sortable: true,
    },
  ];
  perPageList = [25, 50, 100, 250];
  perPage = 100;
  orderby = "id";
  orderDirection = "asc";
  totalItems = 0;
  offsetPage = 0;
  tableRows: any[] = [];
  filteredRows: any[] = [];
  user: any = {};
  userId = "";
  previousMNumber = "";
  // agency= ''
  agencies: any[] = [];
  selectEmployeesAgency: any[] = [];
  selectedEmployee = "";
  selectAssignedEmployeesAgency: any[] = [];
  selectAssignedEmployeesAgencyGrouped: any[] = [];
  selectedAssignedEmployee = "";
  selectedClientToUnassign = "";
  userToDelete: any = "";
  isFortlaufend: any = "";
  isError: boolean = false;
  dropdownSearch: string = "";
  timer: any = null;
  cachedAgencies: any[] = [];
  currentAgencyData: any = [];
  selectYears: any[] = [];
  selectedYear: any = "";
  templateName: any = "";
  messageStatus: any = "";
  selectYearClient: any = "";
  skrTemplates: any = "";
  checkedEmployes: any = [];
  selectAllEmps: any = "";
  constructor() {
    super();
    this.getAgencies();
  }

  mounted() {
    //Support Bootstrap inside vuejs
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.clearOnEsc();
      }
    });
    if (getClientStorage()) {
      store.dispatch("updateSelectedClient", getClientStorage()[0]);
      setAgencyStorage(getClientStorage()[0]);
      this.getMandators();
      this.getEmployees();
    } else if (getAgencyStorage()) {
      store.dispatch("updateSelectedClient", getAgencyStorage());
      this.getMandators();
      this.getEmployees();
    } else {
      this.getMandators();
      this.getEmployees();
    }
  }

  // get skrTemplates() {
  //   return store.getters.skrTemplates || []
  // }

  get clients() {
    return store.getters.clients;
  }

  get client() {
    return store.getters.selectedClient;
  }
  get agency() {
    return store.getters.selectedAgency;
  }

  get fortlaufendCheck() {
    return this.user.endyear == -1 || this.user.endyear == "";
  }

  getSkrYears(client: any) {
    this.selectedYear = "";
    this.templateName = "";
    this.selectYearClient = client.mnumber;

    api
      .getSkrmandyears(this.agency, client.mnumber)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.selectYears = Object.keys(res.data);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
    api
      .getUknowSkrTemplates()
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        let filteredTemplates = res.data;
        this.skrTemplates = filteredTemplates.filter((itm: any) => itm != null);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  clearOnEsc() {
    this.dropdownSearch = "";
    this.cachedAgencies = this.agencies;
    //A way to handle Jquery component inside Vuejs
    const _bodySelector = document.querySelector("body");
    if (_bodySelector) {
      _bodySelector.click();
    }
  }

  loadTemplate(e: any) {
    this.messageStatus = 0;
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    let agencyClient = [this.agency, this.selectYearClient];
    api
      .getMandantSkr(agencyClient, this.selectedYear)
      .then((res) => {
        this.templateName = res.data;
        // this.skrTemplates = [this.templateName]
        store.dispatch("updateLoading", store.state.loading - 1);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  saveSkrTemplate() {
    if (!this.templateName || !this.selectedYear) {
      // this.nameIssue = true
      this.messageStatus = -1;
      return;
    }
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    let topBarDataClient = [this.agency, this.selectYearClient];
    api
      .saveMandantSkr(topBarDataClient, this.selectedYear, this.templateName)
      .then((res) => {
        this.messageStatus = 1;
        this.getMandators();
        store.dispatch("updateLoading", store.state.loading - 1);
      })
      .catch((err) => {
        this.messageStatus = -1;
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  preventDefault(e: any) {
    e.stopPropagation();
  }

  topBarSearch() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.cachedAgencies = this.agencies.filter((itm: any) => {
        if (
          this.dropdownSearch == "" ||
          itm[0].toLowerCase().includes(this.dropdownSearch.toLowerCase()) ||
          itm[1].toLowerCase().includes(this.dropdownSearch.toLowerCase())
        ) {
          return itm;
        }
      });
    }, 300);
  }

  disableYear() {
    if (this.isFortlaufend) {
      this.isFortlaufend = false;
      this.user.endyear = "";
    } else {
      this.isFortlaufend = true;
      this.user.endyear = "";
    }
  }

  @Watch("agency")
  onClientChange() {
    this.getMandators();
    this.getEmployees();
  }

  changefilter(val: any, key: string) {
    this.offsetPage = 0;
    this.getMandators();
    this.getEmployees();
  }

  getAgencieName(agencyId: string) {
    const agency = this.agencies.find((a) => a[0] === agencyId);
    return agency[1];
  }

  getAgencies() {
    api.getAllStbs().then((res) => {
      const data: [string, string][] = res.data;
      const sortedAgencies = data.sort((a, b) => a[0].localeCompare(b[0]));
      this.agencies = sortedAgencies;
      this.cachedAgencies = sortedAgencies;
      if (this.agency == null) {
        store.dispatch("updateSelectedAgency", sortedAgencies[0][0]);
      }
    });
  }
  getEmployees() {
    if (this.agency == null) {
      return null;
    }
    api.getEmployees(this.agency).then((res) => {
      let filtredByLevel = res.data.filter((itm: any) => itm[4] == 2);
      this.selectEmployeesAgency = filtredByLevel;
    });
  }

  unassignEmployee(event: any) {
    this.selectedClientToUnassign = event.mnumber;
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .getMandatorEmployees(this.agency, event.mnumber)
      .then((res) => {
        this.selectAssignedEmployeesAgency = res.data.filter(
          (itm: any) => itm[4] !== 0
        );

        store.dispatch("updateLoading", store.state.loading - 1);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
    $("#unassign_modal").modal();
  }

  showAssignedClients(event: any) {
    const groupBy = (items: any, key: any) =>
      items.reduce(
        (result: any, item: any) => ({
          ...result,
          [item[key]]: [...(result[item[key]] || []), item],
        }),
        {}
      );
    this.selectedClientToUnassign = event.mnumber;
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .getMandatorEmployees(this.agency, event.mnumber)
      .then((res) => {
        this.selectAssignedEmployeesAgency = res.data;
        this.selectAssignedEmployeesAgencyGrouped = groupBy(res.data, 4);
        store.dispatch("updateLoading", store.state.loading - 1);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
    $("#assigned_clients_modal").modal();
  }

  async skrZuweisenModal(event: any) {
    let testYears = await this.getSkrYears(event);
    $("#skr_zuweisen_modal").modal();
  }

  getMandatorEmployees() {
    if (this.client) {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .getMandatorEmployees(this.client[0], this.client[1])
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  getMandators() {
    if (this.agency) {
      this.pushStateHistory();

      this.tableRows = [];
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .getMandators(this.agency)
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          res.data.forEach((d: any) => {
            this.tableRows.push({
              id: d[0],
              agency: this.agency,
              name: d[1],
              mnumber: d[2],
              doexport: d[3],
              active: d[4],
              startyear: d[5],
              startmonth: d[6],
              endyear: d[7],
              endmonth: d[8],
              created: d[9],
              consultantnumber: d[10],
              skr: d[11],
            });
          });
          this.filteredRows = this.tableRows;
          this.totalItems = this.filteredRows.length;
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }
  pushStateHistory() {
    // const selectedStb = (this.agency != null) ? this.agency : stb
    let path =
      "/einstellungen/kanzlei/mandanten" +
      "/" +
      encodeURIComponent(this.agency);
    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  setAgency(agency: any) {
    localStorage.setItem("agency", JSON.stringify(agency));
    setAgencyStorage(agency);
    store.dispatch("updateSelectedAgency", agency);
  }

  async addModal() {
    // Get agency data
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    await api
      .getStb(this.agency)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.currentAgencyData = res.data;
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });

    this.user = {
      name: "",
      mnumber: "",
      doexport: true,
      startmonth: 1,
      startyear: 2020,
      endmonth: 12,
      endyear: -1,
      consultantnumber: this.currentAgencyData.consultantnumber,
    };
    this.userId = "";
    this.isFortlaufend = true;

    $("#add_modal").modal();
  }

  async edit(event: any) {
    this.user = event;
    this.userId = event.id;
    this.previousMNumber = this.user.mnumber;
    this.isFortlaufend =
      this.user.endyear == "" ||
      this.user.endyear <= 0 ||
      this.user.endyear == "fortlaufend";

    // Get Agency data
    // Get agency data
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    await api
      .getStb(this.agency)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.currentAgencyData = res.data;
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });

    let tmpObj: any = {};

    if (this.user.active == "ja") {
      tmpObj.active = true;
    }
    if (this.user.active == "nein") {
      tmpObj.active = false;
    }
    if (this.user.doexport == "ja") {
      tmpObj.doexport = true;
    }
    if (this.user.doexport == "nein") {
      tmpObj.doexport = false;
    }
    if (
      this.user.consultantnumber == null ||
      this.user.consultantnumber == ""
    ) {
      tmpObj.consultantnumber = this.currentAgencyData.consultantnumber;
    }
    this.user = { ...this.user, ...tmpObj };
    // this.user.endyear    = ''
    $("#add_modal").modal();
  }

  changePermission(event: any) {
    this.user = event;
    this.userId = event.id;
    $("#permission_modal").modal();
  }

  onDeleteModal(event: any) {
    $("#delete_modal").modal();
    this.userToDelete = event.mnumber;
  }

  deleteItem(event: any) {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .deleteMandator(this.agency, this.userToDelete)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.getMandators();
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  assignEmployee(event: any) {
    this.user = event;
    this.userId = event.id;

    this.selectedClientToUnassign = event.mnumber;
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );

    api
      .getMandatorEmployees(this.agency, event.mnumber)
      .then((res) => {
        this.selectAssignedEmployeesAgency = res.data;

        this.checkedEmployes = [];
        this.selectAllEmps = false;
        this.selectEmployeesAgency.forEach((itm) => {
          if (
            this.selectAssignedEmployeesAgency.findIndex(
              (em) => em[0] == itm[0]
            ) >= 0
          ) {
            this.checkedEmployes.push(itm);
          }
        });

        store.dispatch("updateLoading", store.state.loading - 1);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });

    $("#assign_modal").modal();
  }

  selectAllEmployes(state: boolean, mandant: any) {
    if (state == false) {
      this.checkedEmployes = mandant;
    } else {
      this.checkedEmployes = [];
    }
    this.selectAllEmps = !this.selectAllEmps;
  }

  linkClient(event: any, mandator: any) {
    // console.log((this.agency, mandator[2], this.userId))
    // this.selectAllEmps = (mandator.length == this.checkedEmployes) ? true : false
  }
  linkAllClients() {
    let itemsToAssign = this.checkedEmployes.map((itm: any) => itm[0]);

    let allAssignedEmployeesAgency = this.selectEmployeesAgency.map(
      (itm: any) => itm[0]
    );

    let mandantsList = {
      userids: itemsToAssign,
      mnumbers: [this.user.mnumber],
    };

    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );

    api
      .batchUnlinkEmployeesToMandators(this.agency, {
        userids: allAssignedEmployeesAgency,
        mnumbers: [this.user.mnumber],
      })
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);

        api
          .batchLinkEmployeesToMandators(this.agency, mandantsList)
          .then((res) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            $("#assign_clients_modal").modal("hide");
          })
          .catch((err) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            $("#assign_clients_modal").modal("hide");
          });

        // $('#assign_clients_modal').modal('hide')
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        api
          .batchLinkEmployeesToMandators(this.agency, mandantsList)
          .then((res) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            $("#assign_clients_modal").modal("hide");
          })
          .catch((err) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            $("#assign_clients_modal").modal("hide");
          });
      });
  }

  assignClientToAgency() {
    //linkEmployeeMandator
    api
      .linkEmployeeMandator(
        this.agency,
        this.user.mnumber,
        this.selectedEmployee
      )
      .then((res) => {
        // let getAgencies = Object.keys(res.data)
        // this.agencies   = getAgencies
      });
  }
  unassignClientToAgency() {
    api
      .unlinkEmployeeMandator(
        this.agency,
        this.selectedClientToUnassign,
        this.selectedAssignedEmployee
      )
      .then((res) => {
        // let getAgencies = Object.keys(res.data)
        // this.agencies   = getAgencies
      });
  }

  add() {
    let tmpObj: any = {};

    if (
      this.user.endyear == "" ||
      this.user.endyear <= 0 ||
      this.user.endyear == "fortlaufend"
    ) {
      tmpObj.endyear = -1;
    }

    if (this.user.active == "ja") {
      tmpObj.active = true;
    }
    if (this.user.active == "nein") {
      tmpObj.active = false;
    }
    if (this.user.doexport == "ja") {
      tmpObj.doexport = true;
    }
    if (this.user.doexport == "nein") {
      tmpObj.doexport = false;
    }

    this.user = { ...this.user, ...tmpObj };
    if (
      this.user.name == "" ||
      this.user.mnumber == "" ||
      this.user.startmonth == "" ||
      this.user.startyear == "" ||
      this.user.endmonth == ""
    ) {
      this.isError = true;
      return false;
    } else {
      this.isError = false;
      $("#add_modal").modal("hide");

      if (this.userId) {
        api
          .updateMandator(this.agency, this.previousMNumber, this.user)
          .then((res) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            this.getMandators();
          })
          .catch((err) => {
            store.dispatch("updateLoading", store.state.loading - 1);
          });
      } else {
        store.dispatch(
          "updateLoading",
          store.state.loading >= 0 ? store.state.loading + 1 : 1
        );
        api
          .postMandator(this.agency, this.user)
          .then((res) => {
            store.dispatch("updateLoading", store.state.loading - 1);
            this.getMandators();
          })
          .catch((err) => {
            store.dispatch("updateLoading", store.state.loading - 1);
          });
      }
    }
  }

  toggle() {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .toggleMandator(this.agency, this.user.mnumber, this.user.active)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.getMandators();
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  search(event: any) {
    if (event) {
      this.filteredRows = this.tableRows.filter((row) => {
        return (
          row.name.toLowerCase().includes(event.toLowerCase()) ||
          (row.active &&
            row.active
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.consultantnumber &&
            row.consultantnumber
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.created &&
            row.created
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.doexport &&
            row.doexport
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.endmonth &&
            row.endmonth
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.endyear &&
            row.endyear
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.id &&
            row.id
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.mnumber &&
            row.mnumber
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.name &&
            row.name
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.skr &&
            row.skr
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.startmonth &&
            row.startmonth
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.startyear &&
            row.startyear
              .toString()
              .toLowerCase()
              .includes(event.toString().toLowerCase())) ||
          (row.agency && row.agency.toLowerCase().includes(event.toLowerCase()))
        );
      });
    } else {
      this.filteredRows = this.tableRows;
    }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = "asc";
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
      }
    }
  }
}
